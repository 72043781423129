<template>
	<tfoot class="tf-sticky">
		<tr class="has-background-white" style="border-top: 1px solid #ededed;">
			<th></th>
			<th>{{data.Weight | number}} Kg</th>
			<!-- <th>{{data.Height | number}} x {{data.Width | number}} x {{data.Depth | number}} m</th> -->
			<th></th>
			<!-- <th></th> -->
			<th v-if="truck_job.status_id >= 0"></th>
			<th v-if="truck_job.status_id >= 0"></th>
		</tr>
	</tfoot>
</template>

<script>
	export default{
		props: ["data", "truck_job"]
	}
</script>

<style scoped>

</style>

